@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  width: calc(var((--width-contain), auto) * 1%);
  height: calc(var((--height-contain), auto) * 1%);
}

// For ContentBlock instances that are meant to function as a
// clickable link.
.clickable {
  cursor: pointer;

  img {
    transition: all 0.5s cubic-bezier(0.22, 0.01, 0.01, 0.99);
  }

  &:hover {
    img {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
}

.image {
  width: var(--image-width, 100%);
  margin: auto;
  overflow: hidden;
}

.flip {
  @include md-min {
    .image {
      order: 1;
      padding-right: 0 !important;
      padding-left: $space-xs;
    }
  }
}

// Set all possible alignments across display properties (e.g. block, flex, grid)
.center {
    align-items: center;
    text-align: center;
    margin-inline: auto;
    justify-content: center;

    .buttonContainer {
        align-items: center;
    }
}

.text {
  height: 100%;
}

.overlay {
  position: relative;
  margin: 0;

  .image {
    z-index: 0;
  }

  .text {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.sideBySide {
  display: flex;
  flex-direction: row;
  align-items: center;

  .image {
    width: var(--image-width, 100%);
  }

  .text {
    width: 100%;
  }

  @include md-min {
    .image {
      width: 50%;
      padding-right: $space-xs;
    }

    .text {
      width: 50%;
      padding: 0 $space-md;
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
  gap: $space-md;
  align-items: center;

  .image {
    width: var(--image-width, 100%);
  }

  .text {
    display: contents;
  }

  @include md-min {
    flex-direction: row;

    .text {
      width: 50%;
      padding: 0 $space-md;
    }
  }
}

// Styles applied to teaser when used as an accordion button in the ContentAccordion component
.accordionButton {
  display: flex;
  flex-direction: column;
  gap: $space-md;
  align-items: center;

  .image {
    width: var(--image-width, 100%);
  }

  .text {
    display: contents;
  }

  @include md-min {

    display: grid;
    grid-template-columns: 1fr 2fr 4fr;

    .text {
      width: 50%;
      padding: 0 $space-md;
    }
  }
}

@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.clickable:where(:not(.overlay)) {
  position: relative;

  h3 {
    transition: color 0.2s ease-out;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    z-index: 1;
    border-top: 1px solid $color-secondary-200;

    @include md-min {
      width: 50vw;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &:hover {
    h3 {
      color: $color-white;
    }
  }
}

// Borders need to be treated differently depending on where
// the ContentBlock sits in the row. Use the parent element's
// placement index to determine the correct border styling.
div[data-banner-index="0"] {
  .clickable:where(:not(.overlay)) {
    &::before,
    &::after {
      right: 0;
    }
  }
}

div[data-banner-index="1"] {
  .clickable:where(:not(.overlay)) {
    &::before,
    &::after {
      left: 0;
    }
  }
}
